import React from 'react';
//import logo from './logo.svg';
import ZiusCloud from './ZiusCloud'
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import './App.css';

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#ffff8d',
    },
    secondary: {
      main: '#ea80fc',
    },
  },
})

function App() {
  return (
    <div>
      <div id="App" className="App"></div >
      <MuiThemeProvider theme={theme}>
        <ZiusCloud />
      </MuiThemeProvider>
    </div >
  );
}

export default App;