import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import * as THREE from 'three';

import Grid from '@material-ui/core/Grid';

import './App.css';
import Globe from 'globe.gl';
import montserratjson from './fonts/Montserrat-Regular.json'

const styles = theme => ({
    root: {
        position: 'absolute',
        top: 0,
        zIndex: 1,
    },
    formRoot: {
        fontFamily: 'Montserrat',
        fontWeight: '100',
        fontSize: '14px',
        letterSpacing: '0px',
        paddingTop: '100px',
        color: '#607d8b',
    },
    contactUs: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: '-50px',
        marginLeft: '-150px',
        width: '300px',
        height: '100px',
        border: '0px solid red',
        fontFamily: 'Montserrat',
        fontWeight: '100',
        fontSize: '14px',
        letterSpacing: '0px',
        color: '#607d8b',
    },
    email: {
        fontFamily: 'Montserrat',
        fontWeight: '100',
        fontSize: '14px',
        letterSpacing: '0px',
        color: 'red',
    },
    input: {
        color: 'white'
    },
    heading: {
        [theme.breakpoints.up('md')]: {
            // color: '#b0bec5',
            //color: '#bbdefb',
            fontFamily: 'Montserrat',
            fontWeight: '100',
            fontSize: '28px',
            letterSpacing: '0px',
            paddingTop: '50px',
            color: '#424242',
        },
    },
    subHeading: {
        fontFamily: 'Montserrat',
        fontWeight: '100',
        fontSize: '14px',
        letterSpacing: '0px',
        paddingTop: '10px',
        color: '#607d8b',
        // zIndex: 1,
    },
    footerHeading: {
        // [theme.breakpoints.up('md')]: {

        // color: '#b0bec5',
        //color: '#bbdefb',
        fontFamily: 'Montserrat',
        fontWeight: '100',
        fontSize: '18px',
        color: '#424242',
        paddingTop: '10px',
        // },
    },
    footerSubHeading: {
        fontFamily: 'Montserrat',
        fontWeight: '100',
        fontSize: '10px',
        letterSpacing: '0px',
        color: '#607d8b',
        // zIndex: 1,
    },
    footer: {
        position: 'absolute',
        bottom: '0px',
        width: '100%',
        zIndex: 1,
        height: '80px',
    },
    grad: {
        // backgroundImage: '-webkit-gradient(linear, left top, right top, color-stop(0, #f22), color-stop(0.15, #f2f), color-stop(0.3, #22f), color-stop(0.45, #2ff), color-stop(0.6, #2f2), color-stop(0.75, #2f2), color-stop(0.9, #ff2), color-stop(1, #f22))',
        // backgroundImage: 'gradient(linear, left top, right top, color-stop(0, #f22), color-stop(0.15, #f2f), color-stop(0.3, #22f), color-stop(0.45, #2ff), color-stop(0.6, #2f2), color-stop(0.75, #2f2), color-stop(0.9, #ff2), color-stop(1, #f22))',
        // backgroundImage: '-webkit-gradient(linear, left top, right top,color-stop(0, #f22),color-stop(0.15, #f2f))',
        backgroundImage: 'linear-gradient(to right, red,blue)',
        color: 'transparent',
        '-webkit-background-clip': 'text',
        backgroundClip: 'text',
    },
    textBanner: {
        // width: '100%',
        // height: '100%',
        fontSize: 50,
        fontFamily: 'sansSerif',
        position: 'absolute',
        color: 'white',
        top: 0,
        display: 'flex',
        justifyContent: 'center',
        alignItems: "center",
    },
    inputWidth: {
        width: 200,
    }
});

// const N = 20;
const arcsData = [...Array(20).keys()].map(() => ({
    startLat: (Math.random() - 0.5) * 180,
    startLng: (Math.random() - 0.5) * 360,
    endLat: (Math.random() - 0.5) * 180,
    endLng: (Math.random() - 0.5) * 360,
    color: [['red', 'blue'][Math.round(Math.random() * 1)], ['red', 'blue'][Math.round(Math.random() * 1)]]
}));


const lData = [...Array(1).keys()].map(() => ({
    lat: 1,
    lng: 5,
    alt: 4,
    radius: 3,
    color: '#0000FF',
}));

const N_PATHS = 10;
const MAX_POINTS_PER_LINE = 10000;
const MAX_STEP_DEG = 1;
const MAX_STEP_ALT = 0.015;
const gData = [...Array(N_PATHS).keys()].map(() => {
    let lat = (Math.random() - 0.5) * 90;
    let lng = (Math.random() - 0.5) * 360;
    let alt = 0;

    return [[lat, lng, alt], ...[...Array(Math.round(Math.random() * MAX_POINTS_PER_LINE)).keys()].map(() => {
        lat += (Math.random() * 2 - 1) * MAX_STEP_DEG;
        lng += (Math.random() * 2 - 1) * MAX_STEP_DEG;
        alt += (Math.random() * 2 - 1) * MAX_STEP_ALT;
        alt = Math.max(0, alt);
        return [lat, lng, alt];
    })];
});

const places = {
    "type": "FeatureCollection",
    "features": [
        {
            "type": "Feature",
            "properties": {
                "scalerank": 8,
                "natscale": 10,
                "labelrank": 3,
                "featurecla": "Admin-0 capital",
                "name": "Go Further..\n\nMission: Voyager 2 \nLaunch Date: Mon, 05 Sept 1977\nDistance from Earth: 13,802,584,382 mi\nCourtesy: NASA Jet Propulsion Laboratory",
                "namepar": null,
                "namealt": null,
                "diffascii": 0,
                "nameascii": "Vatican City",
                "adm0cap": 1,
                "capalt": null,
                "capin": null,
                "worldcity": 1,
                "megacity": 0,
                "sov0name": "Vatican (Holy Sea)",
                "sov_a3": "VAT",
                "adm0name": "Vatican (Holy See)",
                "adm0_a3": "VAT",
                "adm1name": "Lazio",
                "iso_a2": "VA",
                "note": null,
                "latitude": 0,
                "longitude": 5,
                "changed": 4,
                "namediff": 0,
                "diffnote": "Changed scale rank.",
                "pop_max": 832,
                "pop_min": 832,
                "pop_other": 562430,
                "rank_max": 2,
                "rank_min": 2,
                "geonameid": 6691831,
                "meganame": null,
                "ls_name": "Vatican City",
                "ls_match": 1,
                "checkme": 0,
                "min_zoom": 7,
                "color": "#c62828",
            },
            "bbox": [
                12.453387,
                41.903282,
                12.453387,
                41.903282
            ],
            "geometry": {
                "type": "Point",
                "coordinates": [
                    12.453387,
                    41.903282
                ]
            }
        }],
    "bbox": [
        -175.220564477617,
        -41.2999878536917,
        179.216647094029,
        64.1500236197392
    ]
}

const blankPlaces = {
    "type": "FeatureCollection",
    "features": [
        {
            "type": "Feature",
            "properties": {
                "scalerank": 8,
                "natscale": 10,
                "labelrank": 3,
                "featurecla": "Admin-0 capital",
                "name": "",
                "namepar": null,
                "namealt": null,
                "diffascii": 0,
                "nameascii": "Vatican City",
                "adm0cap": 1,
                "capalt": null,
                "capin": null,
                "worldcity": 1,
                "megacity": 0,
                "sov0name": "Vatican (Holy Sea)",
                "sov_a3": "VAT",
                "adm0name": "Vatican (Holy See)",
                "adm0_a3": "VAT",
                "adm1name": "Lazio",
                "iso_a2": "VA",
                "note": null,
                "latitude": 0,
                "longitude": 5,
                "changed": 4,
                "namediff": 0,
                "diffnote": "Changed scale rank.",
                "pop_max": 832,
                "pop_min": 832,
                "pop_other": 562430,
                "rank_max": 2,
                "rank_min": 2,
                "geonameid": 6691831,
                "meganame": null,
                "ls_name": "Vatican City",
                "ls_match": 1,
                "checkme": 0,
                "min_zoom": 7,
                "color": "#c62828",
            },
            "bbox": [
                12.453387,
                41.903282,
                12.453387,
                41.903282
            ],
            "geometry": {
                "type": "Point",
                "coordinates": [
                    12.453387,
                    41.903282
                ]
            }
        }],
    "bbox": [
        -175.220564477617,
        -41.2999878536917,
        179.216647094029,
        64.1500236197392
    ]
}

const textInnovate = ['INNOVATE', 'WITH', 'SPEED.', 'GO FURTHER.', ''];
const labelTexts = [
    'INNOVATION WITH SPEED',
    'SECURE & FAST CONTENT DELIVERY',
    'CONTINOUS IMPROVEMENT & CONTINOUS SCALING',
    'ADAPTIVE GLOBAL INFRASTRUCTURE',
    'PRECISION LEVEL TECHNOLOGY DEVELOPMENT',
    'INDUSTRY-LEADING CONSISTENT HIGH PERFORMANCE',
    'INDUSTRY-LEADING SECURITY AND GOVERNANCE',
    'THREAT DETECTION & CONTINOUS MONITORING',
    'WORLD-CLASS DELIVERY PRACTICES',
    'ENTERPRISE READY RAPID DELIVERY',
    'GLOBAL PRODUCT AVAILABILITY & LAUNCH',
    'STRATEGIC GLOBAL MARKETING',
    'INTEGRATED DIGITAL MEDIA MARKETING & ADVERTISING',
    'REVENUE GENERATING BUSINESS STREAMS',
    'GLOBAL DIGITAL BRAND IDENTITY',
    'MULTI-GENERATION PRODUCT LIFECYCLE'];

const textInnovateTimeout = ['1000', '500', '1500', '1000']
// const textInnovateGlobe = ['0', '1', '1', '1', '0']

const globe = Globe()
    .globeImageUrl('//unpkg.com/three-globe/example/img/earth-night.jpg')
    .bumpImageUrl('//unpkg.com/three-globe/example/img/night-sky.png')
    .backgroundImageUrl('//unpkg.com/three-globe/example/img/night-sky.png')

class ZiusCloud extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            textInnovateCtr: 0,
            currentText: '',
            long: -90,
            animate: false,
            showForm: false,
        }

        // this.animateInnovation2 = this.animateInnovation2.bind(this)
        this.customLabels = {
            type: "FeatureCollection",
            features: [
                {
                    type: "Feature",
                    properties: {
                        name: "something",
                        latitude: 0,
                        longitude: 5,
                    }
                }],
        }
        this.earthRotationTimer = null;
        this.labelRotationTimer = null;
        this.toLat = 0;
        this.toLng = 0;
    }

    componentDidMount() {
        // console.log("COMPONENT DID MOUNT");
        globe
            .pointOfView({ lat: -41, lng: 146, altitude: 5 }, 100)
            (document.getElementById('App'));
        setTimeout(() => {
            this.animateInnovation2();
        }, 2000)

    }

    componentDidUpdate() {
        // console.log("COMPONENT DID UPDATE");
    }

    AnimateInnovateBanner() {
        this.setState({
            currentText: textInnovate[this.state.textInnovateCtr],
            textInnovateCtr: this.state.textInnovateCtr + 1,
        });
        if (this.state.textInnovateCtr < 5) {
            if (this.state.textInnovateCtr === 4)
                globe
                    .pointOfView({ lat: 0, lng: 0, altitude: 2.5 }, 1500)
                    .pathPointAlt(pnt => 0) // set altitude accessor
                    .pathTransitionDuration(500)
            setTimeout(() => {
                this.AnimateInnovateBanner();
            }, textInnovateTimeout[this.state.textInnovateCtr - 1]);
        }
    }

    animateInnovation() {
        globe
            // .arcsData(null)
            // .arcColor('color')
            // .arcDashLength(() => Math.random())
            // .arcDashGap(() => Math.random())
            // .arcDashAnimateTime(0)
            .pointOfView({ lat: -41, lng: 146, altitude: 7 }, 0)
        // (document.getElementById('App'));

        setTimeout(() => {
            globe
                .pathsData(gData)
                .pathColor(() => ['rgba(0,0,255,0.4)', 'rgba(255,0,0,0.4)'])
                .pathDashLength(0.8)
                .pathDashGap(0.004)
                .pathDashAnimateTime(100000)
            setTimeout(() => {
                globe
                    // .pathPointAlt(pnt => pnt[1]) // set altitude accessor
                    .pathPointAlt(pnt => pnt[2])
                    .pathTransitionDuration(2000)
                setTimeout(() => {
                    globe
                        .pathPointAlt(pnt => pnt[1.5]) // set altitude accessor
                        .pathTransitionDuration(400)
                    setTimeout(() => {
                        globe
                            .pointOfView({ lat: 0, lng: -10, altitude: 7 }, 1000)
                            .labelsData(places.features)
                            .labelLat(d => d.properties.latitude)
                            .labelLng(d => d.properties.longitude)
                            .labelText(d => d.properties.name)
                            // .labelSize(d => Math.sqrt(d.properties.pop_max) * 4e-4)
                            .labelSize(1.5)
                            // .labelDotRadius(d => Math.sqrt(d.properties.pop_max) * 4e-4)
                            // .labelDotRadius(2.5)
                            .labelAltitude(4)
                            .labelColor(() => '#FF0000')
                            .labelResolution(2)
                            .customLayerData(lData)
                            .customLayerLabel(d => d.label)
                            .customThreeObject(d => new THREE.Mesh(
                                new THREE.SphereBufferGeometry(d.radius),
                                new THREE.MeshLambertMaterial({ color: d.color })
                            ))
                            .customThreeObjectUpdate((obj, d) => {
                                Object.assign(obj.position, globe.getCoords(d.lat, d.lng, d.alt));
                            });
                    }, 200)
                }, 2000)
            }, 1500)
        }, 500)
    }

    animateInfrastructure() {
        console.log(arcsData);
        globe
            .labelsData(blankPlaces.features)
            .labelLat(d => d.properties.latitude)
            .labelLng(d => d.properties.longitude)
            .labelText(d => d.properties.name)
            .labelSize(1.5)
            .labelAltitude(15)
            .labelColor(() => '#FF0000')
            .labelResolution(2)
            // .pathsData(blankGData)
            // .pathColor(() => ['rgba(0,0,255,0.4)', 'rgba(255,0,0,0.4)'])
            // .pathDashLength(0.8)
            // .pathDashGap(0.004)
            // .pathDashAnimateTime(0)
            .customLayerData(null)
            // .pathsData(null)
            .pointOfView({ lat: -41, lng: 146, altitude: 7 }, 0)
        setTimeout(() => {
            globe
                // .globeImageUrl('//unpkg.com/three-globe/example/img/earth-night.jpg')
                .arcsData(arcsData)
                .arcColor('color')
                .arcDashLength(() => Math.random())
                .arcDashGap(() => Math.random())
                .arcDashAnimateTime(() => Math.random() * 4000 + 500)
        }, 2000)
    }

    rotateEarth() {
        this.toLat = (Math.random() - 0.5) * 90;
        this.toLng = (Math.random() - 0.5) * 360;
        globe
            .pointOfView({ lat: this.toLat, lng: this.toLng, altitude: 5 }, 1000)
    }

    setRotationTimeout() {
        console.log("rotating Earth");
        this.earthRotationTimer = setTimeout(() => {
            this.rotateEarth();
            this.setRotationTimeout();
        }, 5000);
    }

    changeLabel() {
        // this.customLabels.features[0].properties.latitude = (Math.random() - 0.5) * 90;
        // this.customLabels.features[0].properties.longitude = (Math.random() - 0.5) * 360;

        // this.customLabels.features[0].properties.latitude = globe.pointOfView().lat;
        // this.customLabels.features[0].properties.longitude = globe.pointOfView().lng;
        this.customLabels.features[0].properties.latitude = this.toLat;
        this.customLabels.features[0].properties.longitude = this.toLng;
        this.customLabels.features[0].properties.name = labelTexts[Math.floor(Math.random() * Math.floor(20))];
        globe
            .labelsData(this.customLabels.features)
            .labelLat(d => d.properties.latitude)
            .labelLng(d => d.properties.longitude)
            .labelText(d => d.properties.name)
            .labelsTransitionDuration(2000)
            // .labelsRotation(1)
            .labelTypeFace(montserratjson)
            .labelSize(4.5)
            .labelAltitude(0.5)
            .labelColor(() => '#607d8b')
            .labelResolution(2)
        // this.labelTimeout();
    }

    setLabelTimeout() {
        console.log("Changing label");
        this.labelRotationTimer = setTimeout(() => {
            this.changeLabel();
            this.setLabelTimeout();
        }, 5000);
    }

    stopAnimation() {
        // setTimeout(() => {
        clearTimeout(this.earthRotationTimer);
        clearTimeout(this.labelRotationTimer);
        // }, 2000)
    }

    animateInnovation2() {
        this.setState({
            showForm: false,
        });

        setTimeout(() => {
            this.rotateEarth();
            this.setRotationTimeout();
        }, 1000)
        setTimeout(() => {
            this.changeLabel();
            this.setLabelTimeout();
        }, 2000)

        // setTimeout(() => {
        globe
            // .pointOfView({ lat: (Math.random() - 0.5) * 90, lng: (Math.random() - 0.5) * 360, altitude: 5 }, 1000)
            .pathsData(gData)
            .pathColor(() => ['rgba(0,0,255,0.4)', 'rgba(255,0,0,0.4)'])
            .pathDashLength(0.8)
            .pathDashGap(0.004)
            .pathDashAnimateTime(100000)
            .arcsData(arcsData)
            .arcColor('color')
            .arcDashLength(() => Math.random())
            .arcDashGap(() => Math.random())
            .arcDashAnimateTime(() => Math.random() * 4000 + 500)

        // this.rotateEarth();
        // this.changeLabel();
        // }, 1000)
    }

    contactUs() {
        this.stopAnimation();
        this.customLabels.features[0].properties.name = "";
        globe
            .labelsData(this.customLabels.features)
            .labelLat(d => d.properties.latitude)
            .labelLng(d => d.properties.longitude)
            .labelText(d => d.properties.name)

        setTimeout(() => {
            globe
                .pointOfView({ lat: -25, lng: 133, altitude: 1 }, 2000)
            this.setState({
                showForm: true,
            });
        }, 1000);
    }

    render() {
        const { classes } = this.props;
        return (
            <div>
                {this.state.showForm
                    ?
                    <div>
                        <Grid container justify={'center'} spacing={0} className={classes.formRoot} >
                            <Grid item xs={12} align="center" className={classes.contactUs}>
                                Thank you for your interest. <br /><br />
                                For digital product ideation & development, please contact us at: <br />
                                <span className={classes.email}>labs@zius.cloud</span>
                            </Grid>
                        </Grid>
                    </div>
                    : null
                }
                <div>
                    <Grid container justify={'center'} className={classes.root} spacing={0} style={{ border: '0px solid blue' }} >
                        <Grid item xs={12} lg={12} align="center" style={{ color: 'black', border: '0px solid red' }}>
                            <div className={classes.heading} onClick={() => this.animateInnovation2()}>
                                <span className={classes.grad} >ZIUS.CLOUD</span>
                            </div>
                        </Grid>
                        <Grid item xs={12} lg={12} align="center">
                            <div className={classes.subHeading}>
                                {/* <span className={classes.grad} >Product Research & Innovation Labs.</span> */}
                                TECHNOLOGY INNOVATION & RESEARCH LABS
                            </div>
                        </Grid>
                    </Grid>
                </div >
                <div >
                    <Grid container justify={'center'} className={classes.footer} spacing={0} style={{ border: '0px solid blue' }} >
                        <Grid item xs={12} lg={12} align="center" style={{ color: 'black', border: '0px solid red' }}>
                            <div className={classes.footerHeading} onClick={() => this.contactUs()}>
                                <span className={classes.grad} >contact us</span>
                                {/* ZIUS.CLOUD */}
                            </div>
                        </Grid>
                        <Grid item xs={12} lg={12} align="center">
                            <div className={classes.footerSubHeading} onClick={() => this.stopAnimation()}>
                                {/* <span className={classes.grad} >Product Research & Innovation Labs.</span> */}
                                2008-2021
                            </div>
                        </Grid>
                    </Grid >
                </div>
            </div >
        );
    }
}

ZiusCloud.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ZiusCloud);